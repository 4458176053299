<template>
  <section class="section container">
    <h1 class="is-uppercase has-text-weight-bold is-size-4 m-b-md">
      Informações sobre o curso
    </h1>

    <div class="columns">
      <div
        :class="[modulos && modulos.completed ? 'is-10' : 'is-12', 'column']"
      >
        <h2 class="is-uppercase has-text-weight-bold m-b-sm">
          {{ course.name }}
        </h2>
        <p class="m-b-md has-text-justified">{{ course.description }}</p>
      </div>

      <div class="column medalha" v-if="modulos && modulos.completed">
        <div>
          <!-- <img v-if="modulos.lessonProgress < 100" src="@/assets/img/medalhaOff.png" alt="imagem medalha" /> -->
          <img src="@/assets/img/medalhaOn.png" alt="imagem medalha" />
          <span v-if="modulos && modulos.examMark" style="color: #597aff">{{
            modulos.examMark
          }}</span>
        </div>
      </div>
    </div>

    <div class="cert">
      <h1 class="is-uppercase has-text-weight-bold is-size-6 m-b-md">
        Progresso do Curso
      </h1>
      <h1
        class="is-uppercase has-text-weight-bold is-size-6 m-b-md link"
        @click="certificado"
        v-if="modulos && modulos.completed"
      >
        Clique aqui para baixar o certificado
      </h1>
    </div>
    <div class="bar-progress m-b-md">
      <span>{{
        modulos.lessonProgress ? modulos.lessonProgress.toFixed(2) + "%" : "0%"
      }}</span>
      <div :style="'width:' + modulos.lessonProgress + '%'"></div>
    </div>

    <div class="columns" v-for="(modulo, index) in modulos" :key="modulo.name">
      <div class="column">
        <div class="box">
          <h3>{{ modulo.name }}</h3>
          <p class="has-text-justified">{{ modulo.description }}</p>
        </div>
      </div>

      <div class="column">
        <table class="table table is-bordered is-fullwidth">
          <tr v-for="lesson in modulo.lessons" :key="lesson._id">
            <td>
              <b-icon
                pack="fa"
                v-if="lesson.completed"
                icon="check"
                type="is-success"
              ></b-icon>
              <b-icon
                pack="fa"
                v-else-if="lesson.available && modulo.available"
                icon="play"
              ></b-icon>
              <b-icon pack="fa" v-else icon="lock"></b-icon>
            </td>
            <td
              @click="
                redirect(lesson.finalLesson, {
                  name: lesson._id,
                  cur: $route.params.name,
                  mod: modulo._id,
                  status: lesson.completed,
                  skip: false,
                })
              "
              v-if="
                (lesson.available && modulo.available && !lesson.completed) ||
                  (lesson.completed && !lesson.finalLesson)
              "
              class="link"
            >
              {{ lesson.name }}
            </td>
            <td v-else>{{ lesson.name }}</td>
            <!-- <td>
              <b-icon pack="fa" icon="check" type="is-success"></b-icon>
              <b-icon pack="fa" icon="check" type="is-success"></b-icon>
              <b-icon pack="fa" icon="check" type="is-success"></b-icon>
            </td>-->
            <td>
              <b-icon class="icon" pack="fa" icon="clock"></b-icon>
              {{ lesson.hourlyLoad + "min" }}
            </td>
          </tr>

          <tr v-if="completed && index === modulos.length - 1">
            <td colspan="100%">
              <a :href="url" target="_blank">Acessar Certificação</a>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="columns">
      <div class="column pdf">
        <a :href="pdf" v-for="pdf in course.docs" :key="pdf">
          <img src="@/assets/img/pdf.png" alt="PDF do curso" />
        </a>
      </div>
    </div>

    <Certificado v-show="userCert" :user="userCert"></Certificado>
  </section>
</template>

<script>
import Axios from "axios";
import Certificado from "./Certificado";

const redirect_url = process.env.VUE_APP_REDIRECT_URL;

export default {
  components: { Certificado },
  data() {
    return {
      url: redirect_url,
      course: [],
      modulos: [],
      status: [],
      user: "",
      userCert: null,
      completed: false,
    };
  },
  methods: {
    redirect(finalLesson, query) {
      this.$router.push({
        name: finalLesson ? "Prova" : "Vídeo",
        params: query,
      });
    },
    async getCourse() {
      try {
        const data = await Axios.get(
          `/v1/courses/info?_id=${this.$route.params.name}`
        );
        this.course = data.data.data[0];
        document.title = this.course.name;

        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async getMe() {
      try {
        const data = await Axios.get(`/v1/users/me`);
        this.user = data.data;
        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async getModulos() {
      try {
        const data = await Axios.get(
          `/v1/user-status/course/${this.$route.params.name}`
        );

        const resp = await Axios.get(`/v1/user-status/me`);

        this.modulos = data.data.modules;
        this.modulos.lessonProgress = data.data.courseProgress;

        const currentCourse = resp.data.find(
          (e) => e.course === this.$route.params.name
        );

        const completed =
          (currentCourse &&
            currentCourse.lesson.length &&
            currentCourse.lesson.length > 0 &&
            currentCourse.lesson.find((e) => e.exam === currentCourse.finalExam)
              .completed) ||
          data.data.completed;

        if (data.data && completed && data.data.hasCertification) {
          this.completed = true;
        }

        return data;
      } catch (error) {
        console.error(error);
      }
    },
    async getAll() {
      await this.getCourse();
      await this.getMe();
      await this.getModulos();
    },
    certificado() {
      this.userCert = {
        nome: this.user.name,
        curso: this.course.name,
        hora: this.course.hourlyLoad,
        data: this.modulos.finalizedAt
          ? this.modulos.finalizedAt
          : this.modulos.updatedAt,
        modulos: this.modulos.finalExam,
      };
    },
  },
  mounted() {
    this.getAll();
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/mixins";

.cert {
  display: flex;
  justify-content: space-between;

  .link {
    color: rgb(95, 183, 255);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.columns {
  border-bottom: 0.1px rgb(241, 241, 241) solid;
  padding-bottom: 1rem;

  .pdf {
    display: flex;
    justify-content: flex-end;

    a {
      width: 100px;
      height: 100px;
    }
  }

  h3 {
    font-weight: bold;
  }

  .link {
    color: rgb(95, 183, 255);

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .table {
    td {
      width: 10%;
      vertical-align: middle;
      text-align: center;

      span {
        vertical-align: middle;
      }

      @include mobile() {
        width: auto !important;
      }
    }

    td + td {
      width: 70%;
      text-align: left;
    }

    td + td + td {
      width: 20%;

      .icon {
        margin-top: -5px;
      }
    }

    td + td + td + td {
      width: 10%;
    }
  }
}

.medalha {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > div {
    position: relative;

    span {
      font-size: 1.2rem;
      position: absolute;
      top: 30px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
    }
  }
}

.bar-progress {
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  height: 1.5rem;
  background-color: rgba($color: #3cb371, $alpha: 0.4);

  span {
    color: #fff;
    font-weight: bold;
    position: absolute;
    left: 48%;
  }

  div {
    transition: 0.2s ease-in-out;
    background-color: #3cb371;
    width: 0%;
    height: 100%;
  }
}
</style>
